@import './App.css';
@import './nav.css';
@import './about.css';
@import './home.css';
@import './blog.css';
@import './resume.css';
@import './contact.css';
@import './project.css';
@import './media.css';

/* @import './construction.css'; */

/* #root {
  background-color: rgb(0, 0, 160);
} */
/* all backgrounds navy for now  */
/* eventually want wht background w/ navy shape behind title */

.res {
  text-align: center;
}

body {
  margin: 0px;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* .ab {
  border-radius: 0.625rem;
}

.tb {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
} */

.shadow {
  box-shadow: 10px 10px #000080, 0 0 20px 0 rgba(90, 97, 105, 0.25);
  /* box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); */
}

.heading {
  background: white;
  margin: 0;
  padding: 20px;
}

.border {
  border: #bc9c22;
  border-style: double;
}
/* if change here, also have to change on resume */

/* color: #dda455 !important; gold color */
/* color: #bc9c22; gold from budgjet mom */
/* color: #737373; gray for font */
/* gold, white, gray, navy */