
.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* color:  #bc9c22; */
    /* is there a way to make the icons gold? */
}

/* next to ea other */

.icons a:link{
  /* display: inline-block; */
  margin: 20px;
  margin-top: 25px;
}

.contact {
  font-family: 'Josefin Slab', serif;
  font-weight: 500;
  color: #5a6169;
  font-size: 25px;
}
a.email {
  font-family: 'Josefin Slab', serif;
  font-weight: 500;
  color: #5a6169;
  font-size: 25px;
}

#blue{
  color: #000080;
  margin-bottom: 0;
}

/* #reach {
  padding: 30px
} */

.info {
  display: inline-block;
  max-width: 40%;
  margin-bottom: 10rem;
  padding: 5rem;
}
