.container {
    display: grid;
    justify-content: center;
    justify-items: center;
    text-align: left;
}

.auto30{
    grid-template-columns: repeat(auto-fit, 30rem);
}

.grid {
    margin-bottom: 4.5rem!important;
}

.grid:nth-child(3n+1) {
    clear: both;
}

.card {
    display: flex;
    flex-direction: column;
    position: center;
    min-width: 0;
    max-width: 20rem;
    height: auto;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border: none; 
}

.card-image {
    position: relative;
    min-height: 13.3125rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.authorImg {
    transform: translateY(50%);
    margin-left: 1.5625rem;
    position: absolute;
    bottom: 0;
    display: flex!important;
}

.authorImg a {
    box-shadow: 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90, 97, 105, 0.5);
    display: block;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    text-indent: -9999px;
    width: 3.1875rem;
    height: 3.1875rem;
}

.postTitle {
    font-family: 'Bad Script', cursive;
    color:  #bc9c22;
    font-size: large;
}

.card-body {
    padding: 8px;
}

.card-title {
    margin: 1rem 0rem;
}

.cardText {
    display: block;
    max-height: 8em;
    font-family: 'Josefin Slab', serif;
    font-weight: 500;
    color: #5a6169;
    font-size: 15px;
}

/* font-family: 'Gotcha', 'Roboto', 'Arial', sans-serif; flatiron sign in font */