.about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
}

#name {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: white;
    margin: 0;
    padding: 20px;
    overflow: hidden;
}

.des {
    font-family: 'Josefin Slab', serif;
    font-weight: 500;
    color: #5a6169;
    font-size: 20px;
    width: 40%;
    text-align: justify;
    padding: 2rem;
}

.cancun {
    width: 50%
}

.can {
    width: 60%;
    height: auto;
    position: sticky;
    position: -webkit-sticky;
    top: 30%
}