
/* .home{
    background-image: url('../images/me.png');
    height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
} */

.bigname {
    font-size: 150px;
    margin: 25px;
    color: #bc9c22;
    font-family: 'Antro Vectra', 'Tangerine', cursive, 'Great Vibes', cursive, 'Bad Script', cursive, 'Parisienne', cursive, 'Allura', cursive;
}

.sub{
    font-size: 33px;
    font-style: italic;
    margin: 15px;
    color: #000080;
}

.hicons{
    position: absolute;
    bottom: 30px;
    right: 10px;
    width: 50px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding:10px;
}