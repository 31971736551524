.auto40{
    grid-template-columns: repeat(auto-fit, 40rem);
}

.crd {
    margin: 5rem;
    max-width: 30rem;
}

.crdImg {
    position: relative;
    max-width: 30rem;
    background-size: cover;
    background-position: center;
}

.crdBdy {
    padding: 8px;
}

.crdTitle {
    font-family: 'Bad Script', cursive;
    color:  #bc9c22;
    font-size: xx-large;
    margin: 8px;
}

.crdTxt {
    display: block;
    font-family: 'Josefin Slab', serif;
    font-weight: 500;
    color: #5a6169;
    font-size: 18px;
}
.crdDes {
    margin: 0;
}

.special {
    margin: 1rem;
    color: #000080;
    text-align: center;
    font-family: 'Tangerine';
    font-size: 29px;
}

.lnk {
    margin: 1rem;
    color:  #bc9c22;
}

.tech {
    margin: 0;
}

.bottom {
    margin-bottom: 30px;
}