h2 {
  text-align: center;
  margin: 5rem;
  font-size: 70px;
  color: #bc9c22;
  font-family: 'Antro Vectra', 'Tangerine', cursive, 'Great Vibes', cursive, 'Bad Script', cursive, 'Parisienne', cursive, 'Allura', cursive;
}

h5 {
  font-size: 23px;
  margin: 0;
  /* color: #bc9c22; */
  color: #000080;
  font-family: 'Bad Script', cursive;
}

h3 {
  font-size: 35px;
  margin: 1rem 0rem;
  color: #bc9c22;
  font-family: 'Bad Script', cursive;
}

#nav{
  font-family: 'Playfair Display', serif;
}

a {
  text-decoration: none;
  color: black;
}

.inline-block {
  display: inline-block;
  margin: 3px;
  padding: 3px;
}

/* .icons a:link{
  display: inline-block;
  margin: 20px;
  margin-top: 25px;
} */

.ta {
  text-align: center;
}